import React from "react";
import { NavLink } from "react-router-dom";
import { css } from "aphrodite/no-important";
import { styles } from "./MainNav.css.js";

function MainNav(props) {
  return (
    <>
      {!props.isPublic && (
        <div className={css(styles.MainNav)}>
          <h3 className={css(styles.MainNav__title)}>Menu</h3>
          <NavLink
            className={({ isActive }) => (isActive ? css(styles.MainNavItem, styles["MainNavItem--is-active"]) : css(styles.MainNavItem))}
            to="/venues"
          >
            <span className={css(styles.MainNavItem__link)}>Mijn locaties</span>
          </NavLink>

          <NavLink
            className={({ isActive }) => (isActive ? css(styles.MainNavItem, styles["MainNavItem--is-active"]) : css(styles.MainNavItem))}
            to="/threads"
          >
            <span className={css(styles.MainNavItem__link)}>Berichten</span>
          </NavLink>

          <NavLink
            className={({ isActive }) => (isActive ? css(styles.MainNavItem, styles["MainNavItem--is-active"]) : css(styles.MainNavItem))}
            to="/membership"
          >
            <span className={css(styles.MainNavItem__link)}>Abonnementen</span>
          </NavLink>
        </div>
      )}
    </>
  );
}

export default MainNav;
