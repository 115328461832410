import React, { useState } from "react";
import { css } from "aphrodite/no-important";
import { styles } from "./Stats.css.js";

function Stats(props) {
  return (
    <div className={css(styles.Requests)}>
      <h1>Stats</h1>
    </div>
  );
}

export default Stats;
