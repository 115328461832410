import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import _ from "lodash";
import { css, StyleSheet } from "aphrodite/no-important";
import { format } from "date-fns";
import { nl } from "date-fns/locale";

import { db } from "../../firebase";
import VenueSelect from "../../components/VenueSelect";
import Message from "../../components/Message";

const styles = StyleSheet.create({
  Threads: {
    margin: "0 auto",
  },
  ThreadCard: {
    background: "#fff",
    borderRadius: "8px",
    padding: "24px",
    marginBottom: "20px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    fontSize: "16px",
  },
  ThreadHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0",
  },
  ThreadName: {
    fontWeight: "600",
    fontSize: "18px",
    color: "#333",
  },
  ThreadEmail: {
    fontWeight: "600",
    color: "#ff3b5c",
  },
  ThreadDate: {
    color: "#666",
    fontSize: "15px",
  },
  ThreadBody: {
    fontSize: "16px",
    lineHeight: "1.6",
    color: "#333",
    marginBottom: "20px",
  },
  ThreadDetails: {
    marginTop: "20px",
    fontSize: "15px",
    color: "#666",
    "> div": {
      marginBottom: "8px",
    },
  },
  ThreadStatus: {
    display: "inline-block",
    padding: "4px 8px",
    borderRadius: "4px",
    backgroundColor: "#e6f3ff",
    color: "#0066cc",
    fontSize: "14px",
    marginRight: "15px",
  },
  NoThreads: {
    textAlign: "center",
    padding: "40px",
    color: "#666",
    fontSize: "16px",
  },
  LoadingState: {
    textAlign: "center",
    padding: "40px",
    color: "#666",
    fontSize: "16px",
  },
});

function Threads() {
  const [threads, setThreads] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedVenue, setSelectedVenue] = useState(null);

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    async function fetchThreads() {
      if (!isMounted) return;

      setError("");
      setLoading(true);

      if (!params.venueId) {
        setThreads([]);
        setLoading(false);
        return;
      }

      try {
        const q = query(collection(db, "conversations"), where("venueId", "==", params.venueId));

        const querySnapshot = await getDocs(q);

        if (!isMounted) return;

        const threadsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSelectedVenue(params.venueId);

        // Sort threads by date
        const sortedThreads = _.orderBy(
          threadsData,
          [
            (thread) => {
              // Safely handle date conversion
              try {
                return thread.messages?.[0]?.date?.toDate?.() || new Date(0);
              } catch (e) {
                console.warn("Invalid date in thread:", thread.id);
                return new Date(0);
              }
            },
          ],
          ["desc"],
        );

        console.log(sortedThreads);

        setThreads(sortedThreads);
      } catch (err) {
        console.error("Error fetching threads:", err);
        if (isMounted) {
          setError("Er is een fout opgetreden bij het ophalen van de berichten.");
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    }

    fetchThreads();

    return () => {
      isMounted = false;
    };
  }, [params.venueId]);

  const handleVenueChange = (value, doc) => {
    if (!doc) {
      setSelectedVenue(null);
      navigate(`/threads`);
      return;
    }
    setSelectedVenue(value);
    navigate(`/threads/${value}`);
  };

  const venueSelector = (
    <VenueSelect activeVenueId={selectedVenue || params.venueId} onChange={handleVenueChange} showNewButton={false} isStandAlone={true} />
  );

  if (!params.venueId) {
    return (
      <div>
        {venueSelector}
        {error && <Message text={error} />}
      </div>
    );
  }

  return (
    <div>
      {venueSelector}

      <div className={css(styles.Threads)}>
        {loading && (
          <div className={css(styles.LoadingState)}>
            <p>Berichten worden geladen...</p>
          </div>
        )}

        {error && <Message text={error} />}

        {!loading && threads.length === 0 && (
          <div className={css(styles.NoThreads)}>
            <p>Geen berichten gevonden voor deze locatie.</p>
          </div>
        )}

        {threads.map((thread) => {
          const firstMessage = thread.messages?.[0] || {};

          let formattedDate = "";
          try {
            if (firstMessage.date?.toDate) {
              formattedDate = format(firstMessage.date.toDate(), "d MMMM yyyy HH:mm", { locale: nl });
            }
          } catch (e) {
            console.warn("Error formatting date for thread:", thread.id);
          }

          return (
            <div key={thread.id} className={css(styles.ThreadCard)}>
              <div className={css(styles.ThreadHeader)}>
                <span className={css(styles.ThreadName)}>{thread.prospectName || "Onbekende naam"}</span>
                <span className={css(styles.ThreadDate)}>{formattedDate || "Datum onbekend"}</span>
              </div>
              <div className={css(styles.ThreadBody)}>{firstMessage.body}</div>
              <div className={css(styles.ThreadDetails)}>
                {thread.messages && thread.messages.length > 1 && <span className={css(styles.ThreadStatus)}>Beantwoord</span>}
                <a href={`mailto:${thread.prospectEmail}`} className={css(styles.ThreadEmail)}>
                  {thread.prospectEmail}
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Threads;
