import React from "react";
import { Route, Routes, Navigate, BrowserRouter, useLocation } from "react-router-dom";
import { auth } from "./firebase.js";
import {
  App,
  Login,
  Venue,
  Action,
  VerifyEmail,
  SignIn,
  Membership,
  Threads,
  Stats,
  Signup,
  Requests,
  ForgotPassword,
  ResetPassword,
  NotFound,
} from "./pages/index.js";

const publicRoutes = ["/login", "/action", "/verify-email", "/forgot-password", "/reset-password", "/signup", "/sign-in"];

function Private(props) {
  const location = useLocation();
  if (!auth.currentUser) {
    return <Navigate replace to="/login" state={{ from: location.pathname }} />;
  }
  return props.children;
}

function Router() {
  return (
    <BrowserRouter>
      <App publicRoutes={publicRoutes}>
        <Routes>
          <Route path="/login" element={<Login name="Login" />} />
          <Route path="/action" element={<Action name="Action" />} />
          <Route path="/verify-email" element={<VerifyEmail name="VerifyEmail" />} />
          <Route path="/sign-in" element={<SignIn name="SignIn" />} />
          <Route path="/forgot-password" element={<ForgotPassword name="ForgotPassword" />} />
          <Route path="/reset-password" element={<ResetPassword name="ResetPassword" />} />
          <Route path="/signup" element={<Signup name="Signup" />} />

          <Route
            path="/membership"
            element={
              <Private>
                <Membership name="Membership" />
              </Private>
            }
          />
          <Route
            path="/stats"
            element={
              <Private>
                <Stats name="Stats" />
              </Private>
            }
          />
          <Route
            path="/threads"
            element={
              <Private>
                <Threads name="Threads" />
              </Private>
            }
          >
            <Route
              path=":venueId"
              element={
                <Private>
                  <Threads name="Threads" />
                </Private>
              }
            />
          </Route>
          <Route
            path="/requests"
            element={
              <Private>
                <Requests name="Requests" />
              </Private>
            }
          />
          <Route path="/">
            <Route
              index
              element={
                <Private>
                  <Venue name="Venue" />
                </Private>
              }
            />
            <Route path="venues">
              <Route
                index
                element={
                  <Private>
                    <Venue name="Venue" />
                  </Private>
                }
              />
              <Route
                path=":venueId"
                element={
                  <Private>
                    <Venue name="Venue" />
                  </Private>
                }
              />
            </Route>
          </Route>
          <Route
            path="*"
            element={
              <Private>
                <NotFound name="NotFound" />
              </Private>
            }
            exact
          />
        </Routes>
      </App>
    </BrowserRouter>
  );
}

export default Router;
