import { StyleSheet } from "aphrodite/no-important";
import { colors, sizes } from "../../styles.js";

const styles = StyleSheet.create({
  Venue: {
    marginBottom: "5rem",
  },
  Venue__card: {
    boxShadow: "0 7px 14px 0 rgba(185,174,162, 0.1), 0 3px 6px 0 rgba(0, 0, 0, .07)",
    background: colors.white,
    padding: "3rem",
    marginBottom: "3rem",
    "@media (max-width: 420px)": {
      padding: "2rem",
    },
  },
  Venue__textfield: {
    background: colors.background,
    border: "none",
    padding: "1rem 1.5rem",
    width: "100%",
    display: "block",
    borderTop: "1px solid #ece9e5",
    borderLeft: "1px solid #ece9e5",
    "::placeholder": {
      color: colors.placeholder,
    },
  },
  Venue__preview: {
    color: colors.primary,
    background: colors.lightPink,
    padding: "1rem 1.5rem",
    float: "right",
    display: "inline-block",
    marginLeft: "3rem",
  },
  Venue__textarea: {
    background: colors.background,
    border: "none",
    padding: "1rem 1.5rem",
    width: "100%",
    borderTop: "1px solid #ece9e5",
    borderLeft: "1px solid #ece9e5",
    lineHeight: "1.3em",
    display: "block",
    "::placeholder": {
      color: colors.placeholder,
    },
  },
  Venue__label: {
    display: "block",
    paddingBottom: "0.8rem",
    fontWeight: "300",
  },
  "Venue__label-inline": {
    display: "inline-block",
    paddingBottom: "0.8rem",
    fontWeight: "500",
    marginLeft: "1rem",
  },
  Venue__premium_label: {
    backgroundColor: "#fe3b5c",
    color: "#fff",
    textTransform: "uppercase",
    padding: "4px 8px",
    borderRadius: "2px",
    fontSize: "12px",
    fontWeight: "500",
    letterSpacing: "1px",
    marginRight: "0.5rem",
    marginBottom: "1rem",
  },
  Venue__premium_message: {
    color: "#aaa",
    fontSize: "15px",
    lineHeight: "2.4rem",
  },
  Venue__row: {
    display: "grid",
    gridGap: "0 2rem",
    marginBottom: "2.6rem",
  },
  Venue__checkboxes: {
    display: "grid",
    gridGap: "1rem",
    gridTemplateColumns: `1fr 1fr`,
    "@media (max-width: 480px)": {
      gridTemplateColumns: "none",
    },
  },
  Venue__checkbox: {
    padding: "1rem",
    background: colors.background,
    cursor: "pointer",
    border: "1px solid transparent",
    borderRadius: "2px",
    borderBottom: "1px solid #ece9e5",
    borderRight: "1px solid #ece9e5",
  },
  Venue__message: {
    padding: "2rem",
    background: colors.lightPink,
    color: colors.primary,
    borderRadius: "4px",
    lineHeight: "1.345em",
  },
  Venue__message__link: {
    textDecoration: "underline",
    cursor: "pointer",
  },
  "Venue__message--is-info": {
    background: "#ff9a001c",
    color: "#ff9a00",
  },
  Venue__helpText: {
    marginBottom: 0,
  },
  "Venue__checkbox--is-checked": {
    background: colors.lightPink,
    color: colors.primary,
    borderTop: `1px solid #f5cbd1`,
    borderLeft: `1px solid #f5cbd1`,
  },
  "Venue__input--is-checkbox": {
    marginRight: "1rem",
    "@media (max-width: 680px)": {
      display: "none",
    },
  },
  "Venue__row--is-last-row": {
    marginBottom: "0",
  },
  "Venue__row--is-1-3": {
    gridTemplateColumns: `${sizes.columnSpan(2)} ${sizes.columnSpan(5)}`,
    "@media (max-width: 680px)": {
      gridTemplateColumns: `${sizes.columnSpan(2)} ${sizes.columnSpan(4)}`,
    },
    "@media (max-width: 480px)": {
      gridTemplateColumns: "none",
    },
  },
  "Venue__row--is-4-1": {
    gridTemplateColumns: `${sizes.columnSpan(6)} ${sizes.columnSpan(1)}`,
    "@media (max-width: 680px)": {
      gridTemplateColumns: `${sizes.columnSpan(5)} ${sizes.columnSpan(1)}`,
    },
    "@media (max-width: 480px)": {
      gridTemplateColumns: "none",
    },
  },
  Venue__grey: {
    color: "grey",
  },
  Venue__row_admin: {
    lineHeight: "200%",
  },
});

export { styles };
