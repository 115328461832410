import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MicroModal from "react-micro-modal";
import _ from "lodash";
import { css } from "aphrodite/no-important";
import { nanoid } from "nanoid";
import { serverTimestamp, doc, setDoc, onSnapshot, query, collection, where } from "firebase/firestore";
import { auth, db } from "../firebase.js";
import { styles } from "./VenueSelect.css.js";
import { styles as ButtonStyles } from "./Button.css.js";
import { styles as ModalStyles } from "../pages/Venue/components/Modal.css.js";
import { styles as VenueStyle } from "../pages/Venue/Venue.css.js";

import Button from "./Button.js";

function VenueSelect(props) {
  const [venues, setVenues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [openNewVenueModal, setOpenNewVenueModal] = useState(false);
  const hasPreloadedFirstItem = useRef(false);

  const navigate = useNavigate();

  useEffect(() => {
    const q = query(collection(db, "locations"), where("uid", "==", auth.currentUser.uid));
    const listener = onSnapshot(q, (snapshot) => {
      const docs = _.map(snapshot.docs, (doc) => ({
        objectID: doc.id,
        ...doc.data(),
      }));
      console.log(docs);
      setVenues(docs);

      if (props.preloadFirstItem && docs.length > 0 && !hasPreloadedFirstItem.current) {
        hasPreloadedFirstItem.current = true;
        if (!props.activeVenueId) {
          props.onChange(docs[0].objectID, docs[0]);
        }
      }
    });
    return () => {
      listener();
    };
  }, [props.preloadFirstItem, props.activeVenueId, props.onChange]);

  async function onChange(e) {
    const selectedVenue = _.find(venues, ["objectID", e.target.value]);
    props.onChange(e.target.value, selectedVenue);
  }

  async function onCreate(e) {
    setLoading(true);

    try {
      const objectID = nanoid(10).toLowerCase();
      await setDoc(doc(db, "locations", objectID), {
        published: false,
        uid: auth.currentUser.uid,
        createdAt: serverTimestamp(),
        name,
      });
      navigate(`/venues/${objectID}`);
    } catch (err) {
      console.log(err);
    }

    setName("");
    setLoading(false);
    setOpenNewVenueModal(false);
  }

  return (
    <>
      <MicroModal open={openNewVenueModal}>
        {(close) => (
          <div className={css(ModalStyles["Modal"])}>
            <div className={css(VenueStyle.Venue__fields)}>
              <div className={css(VenueStyle.Venue__row)}>
                <label>
                  <span className={css(VenueStyle.Venue__label)}>Naam</span>
                  <input
                    type="text"
                    name="name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className={css(VenueStyle.Venue__textfield)}
                  />
                </label>
              </div>
            </div>
            <Button type="submit" pending={loading} loadingLabel="Even wachten..." label="Opslaan" onClick={() => onCreate()} />
            <Button
              type="submit"
              label="sluiten"
              onClick={() => setOpenNewVenueModal(false)}
              pending={loading}
              loadingLabel="Even wachten..."
              ghost={true}
            ></Button>
          </div>
        )}
      </MicroModal>

      <div className={css(styles.VenueSelect)}>
        {venues.length > 0 && (
          <select
            value={props.activeVenueId || ""}
            className={css(styles.VenueSelect__select, styles[props.isStandAlone ? "VenueSelect__select--is-standalone" : ""])}
            onChange={onChange}
          >
            <option value="">Selecteer locatie</option>
            {_.map(venues, (venue) => (
              <option key={venue.objectID} value={venue.objectID}>
                {venue.name || "..."}
              </option>
            ))}
          </select>
        )}
        {props.showNewButton && (
          <div className={css(ButtonStyles.Button, styles.VenueSelect__button)} onClick={() => setOpenNewVenueModal(true)}>
            Nieuwe locatie
          </div>
        )}
      </div>

      {error && <span>{error}</span>}
    </>
  );
}

export default VenueSelect;
